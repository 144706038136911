.particles-js-canvas-el {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background-color: $white;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.custom-select {
    width: unset;
}

.custom-file-label::after {
    content: "Tallózás";
}

input[type="checkbox"]:checked + label::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 9px;
    background: rgba(0, 0, 0, 0);
    top: 8px;
    left: 4px;
    border: 3px solid $color-primary;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

input[type="checkbox"] {
    line-height: 2.1ex;
}

input[type="radio"],
input[type="checkbox"] {
    position: absolute;
    left: -999em;
}

input[type="checkbox"] + label {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

input[type="checkbox"] + label::before {
    content: "";
    display: inline-block;
    vertical-align: -25%;
    height: 3ex;
    width: 3ex;
    background-color: white;
    border: 1px solid rgb(166, 166, 166);
    border-radius: 4px;
    box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.25);
    margin-right: 0.5em;
}

.accordion-link {
    color: $white;
    font-size: 1.25rem;

    &:hover {
        color: $color-secondary;
        text-decoration: underline;
    }
}

.accordion-btn {
    &:focus {
        outline: 0;
        box-shadow: none;
    }
}

.desc {
    font-weight: bold;
    color: $color-primary;
    font-style: italic;
}

