// -----------------------------------------------------------------------------
// This file contain all base element styles.
// -----------------------------------------------------------------------------
body {
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    color: $color-secondary;
}

