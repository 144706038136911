.cookie-info {
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;

    &__card {
        background-color: $white;

        p {
            font-size: 1.125rem;
            line-height: 1.1;
            @include media-breakpoint-up(md) {
                font-size: 1.5rem;
            }
        }

        a {
            font-size: .875rem;
            line-height: 1.2;
            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
            }
        }
    }
}
