.card {
    border-radius: 10px;
    word-break: keep-all;
    word-wrap: unset;

    &-header {
        background-color: $color-tertiary;
        color: $white;
        font-weight: bold;

        &:first-child {
            border-radius: 10px 10px 0 0;
        }
    }
}
