.concert {

    &-header {
        color: $color-secondary;
        font-size: 2rem;
        font-family: Roboto, sans-serif;
        font-weight: bold;

        &--modified {
            font-size: 2.5rem;
        }
    }

    &-card {
        background-color: $white;
        padding: 1.5rem 1rem;
        border: 2px solid $color-primary;
        border-radius: 15px;
        box-shadow: 0 0 15px #c2c2c2;
        font-size: 1rem;

        &--modified {
            margin-bottom: 3rem;
        }
    }

    &-label {
        font-weight: bold;
        color: $color-primary;
    }

}
