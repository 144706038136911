//------------------------------
// This is a generated file DO NOT EDIT!!!!!!!!!!!!!!!!
//------------------------------

@font-face {
	font-family: "svg-font-icons";
	src: url("/assets/svg-font-icons/svg-font-icons.eot?b62c2f9e6ff2d421fd8b87bb81442da2?#iefix") format("embedded-opentype"),
url("/assets/svg-font-icons/svg-font-icons.woff2?b62c2f9e6ff2d421fd8b87bb81442da2") format("woff2"),
url("/assets/svg-font-icons/svg-font-icons.woff?b62c2f9e6ff2d421fd8b87bb81442da2") format("woff");
}

@if not variable-exists('icons') {
	$icons: () !global;
}

$icons: map-merge($icons, (
    'close': ('svg-font-icons' 'f101'),
    'down': ('svg-font-icons' 'f102'),
    'left': ('svg-font-icons' 'f103'),
    'menu': ('svg-font-icons' 'f104'),
    'right': ('svg-font-icons' 'f105'),
    'up': ('svg-font-icons' 'f106'),
));

@mixin icon($name) {
	line-height: 1;
	display: inline-block;
    vertical-align: middle;

	$icon: map-get($icons, $name);
	&:before {
		font-family: nth($icon, 1) !important;
		font-style: normal;
		font-weight: normal !important;
		vertical-align: top;
		content: #{'"\\' + nth($icon, 2) + '"'};
	}
}

.icon {
	line-height: 1;
	display: inline-block;
    vertical-align: middle;
}

.icon:before {
	font-family: svg-font-icons !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.icon--close:before {
	content: "\f101";
}
.icon--down:before {
	content: "\f102";
}
.icon--left:before {
	content: "\f103";
}
.icon--menu:before {
	content: "\f104";
}
.icon--right:before {
	content: "\f105";
}
.icon--up:before {
	content: "\f106";
}
