$color: $color-secondary;
$size: 25px;


@mixin pill() {
    content: '';
    position: absolute;
    width: 100%;
    height: 12%;
    background: $color;
    border-radius: $size;
    transition: all .5s cubic-bezier(.1, .9, 0, 1.2);
}

.wrapper {
    display: block;
    position: relative;
    width: $size;
    height: $size;

    .bun {
        &:before {
            @include pill();
            top: 10%;
            right: 0;
        }

        &:after {
            @include pill();
            bottom: 10%;
            left: 0;
        }

        .burger {
            position: absolute;
            display: flex;
            align-items: center;
            height: $size;
            width: $size;

            &:before, &:after {
                @include pill();
            }
        }
    }

    input {
        display: block;
        position: absolute;
        opacity: 0;
        z-index: 1;
        width: $size;
        height: $size;
        cursor: pointer;

        &:hover ~ .bun {
            &:before, &:after, :before, :after {
                background: darken($color, 5);
            }
        }

        &:checked ~ .bun {
            &:before, &:after {
                width: 0;
            }

            .burger {
                &:before {
                    transform: rotate(135deg);
                }

                &:after {
                    transform: rotate(45deg);
                    box-shadow: 0 0 0 0 lighten($color, 35);
                }
            }
        }
    }

}
