.btn {

    font-size: 1.125rem;

    &-primary {
        border: 0;
        border-radius: 5px;

        &:hover {
            background-color: $color-hover;
        }

        &:active {
            background-color: $color-hover;
            border: 0;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $color-hover;
            border: 0;
        }

        &:focus {
            background-color: $color-hover;
            border: 0;
        }
    }

    &-secondary {
        color: $white;
        background-color: $color-tertiary;
        border: 0;
        border-radius: 5px;

        &:hover {
            background-color: $color-secondary-hover;
        }

        &:active {
            background-color: $color-secondary-hover;
            border: 0;
        }

        &:not(:disabled):not(.disabled):active {
            background-color: $color-secondary-hover;
            border: 0;
        }

        &:focus {
            background-color: $color-secondary-hover;
            border: 0;
        }
    }

    &-link {
        &:hover {
            color: $color-hover;
        }
    }

}



